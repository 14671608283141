import Config from "../config";
import axios from "axios";

//get list of projects
const getUserProjects = ({ filters, mode = "", request = ""}) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/projects?snapshots=${filters}&mode=${mode}&request=${request}`,
  });
};

const getDeletedList = () => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/projects/deleted?snapshots=contents`,
  });
};

//create new project
const postNewProject = (formData) => {
  // console.log('CreatingProject3, f_public:', f_public)

  console.log(formData)


  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
    url: "/projects",
    data: {
      name: formData.name,
      tags: [formData.tags],
      description: formData.description,
      f_public: formData.f_public,
    }
  });
};

// Fork local Copy
const postFork = ({ projectId }) => {
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots/dev/fork`,
  });
};

//Get project info by id
const getProjectById = ({ projectId }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}?snapshots=contents`,
  });
};

//Get project snapshot by project & snapshot Id's
const getProjectSnapshotsById = ({ projectId, snapshotName = 'dev' }) => {

  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots/${snapshotName}`,
  });
};

const patchProjectData = ({ projectId, schema, run_tasks, projectSnapshot }) => {
  let snapshot = 'dev'
  if(Boolean(projectSnapshot)){
    snapshot = projectSnapshot;
  }
  console.log('patchProjectData, projectSnapshot', snapshot)
  const { nodes, links, notes } = schema;
  console.log('patchProjectData, run_tasks', run_tasks)
  console.log('patchProjectData, notes', notes)
  const run_task_id = run_tasks.run_task_id || -1;
  const run_task_flg = run_tasks.run_task_flg || 0;
  console.log('patchProjectData, nodes&links:', nodes, links)

  console.log('patchProjectData, schema:', schema)
  console.log('patchProjectData, runs:', run_task_id, run_task_flg)

  const new_nodes = JSON.parse(JSON.stringify(nodes));



  Object.keys(new_nodes).forEach(node_key => {
    new_nodes[node_key].inputs = {};
    new_nodes[node_key].outputs = {};
    Object.keys(nodes[node_key].inputs).forEach(input_key => {
      new_nodes[node_key].inputs[nodes[node_key].inputs[input_key]['name_id']] = JSON.parse(JSON.stringify(nodes[node_key].inputs[input_key]));
      // delete new_nodes[node_key].inputs[input_key];
    });
    Object.keys(nodes[node_key].outputs).forEach(output_key => {
      new_nodes[node_key].outputs[nodes[node_key].outputs[output_key]['name_id']] = JSON.parse(JSON.stringify(nodes[node_key].outputs[output_key]));
    });
  });



  console.log('patchProjectData, new_nodes:', new_nodes, run_task_id, run_task_flg)
  if (run_task_id !== -1) {
    return axios({
      method: "patch",
      baseURL: Config.baseUrl,
      url: `/projects/${projectId}/snapshots/${snapshot}`,
      data: {
        nodes: new_nodes,
        links: links,
        notes: notes,
        geometry: {
          c_dt: new Date().toJSON(),
          run_task_id,
          run_task_flg
        },
      },
    })}else{
    return axios({
      method: "patch",
      baseURL: Config.baseUrl,
      url: `/projects/${projectId}/snapshots/${snapshot}`,
      data: {
        nodes: new_nodes,
        links: links,
        notes: notes,
        geometry: {
          c_dt: new Date().toJSON().slice(0,19).replace(/T/g,' ')
        },
      },
    })}
};

//Update tags & description of project
const patchProjectInfo = ({ data, projectId }) => {
  console.log('patchProjectInfo:', data);
  console.log('patchProjectInfo:', projectId);
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}`,
    data: {
      name: data.name,
      description: data.description,
      f_public: data.f_public
    },
  });
};

const patchProjectRemove = ({ check, projectId }) => {
  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}`,
    data: {
      data:{
        f_deleted: check
      },
    },
  });
};

//start project
const postRunProject = ({ projectId, input }) => {
  const getInput = () => {
    const inputObj = {};
    inputObj[input] = "";
    return inputObj;
  };
  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots/dev/run`,
    data: {
      // inputs: {},
    },
  });
};

// Upload File
const postProjectFile = ({ formData, projectId }) => {
  for (var key of formData.entries()) {
    console.log("upFile, formData:", key)
  }
  console.log("upFile, projectId:", projectId)


  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/files`,
    data: formData
  });
};

const postProjectFileMS = ({ formData, id }) => {
  for (var key of formData.entries()) {
    console.log("upFile, formData:", key)
  }
  console.log("upFile, projectId:", id)


  return axios({
    method: "post",
    baseURL: Config.baseUrl,
    url: `/files/${id}`,
    data: formData
  });
};

const patchRemove = (formData) => {
  const { project_id, check } = formData;

  console.log('Projects patchRemove', project_id, check)

  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/projects/${project_id}`,
    data: {
      f_deleted: true,
    },
  });
};

const patchRestore = (formData) => {
  const { project_id, check } = formData;

  console.log('Projects patchRemove', project_id, check)

  return axios({
    method: "patch",
    baseURL: Config.baseUrl,
    url: `/projects/${project_id}/restore`,
    data: {
      f_deleted: false,
    },
  });
};

// Download File
const getProjectFile = ({ output_name, task_id, node_id }) => {
  return axios({
    method: "get",
    baseURL: Config.baseUrl,
    url: `/files/${task_id}/${node_id}/${output_name}`,
    responseType: 'arraybuffer',
  });
};

const deleteProjectSnapshotById = ({ projectId, snapshotName }) => {
  return axios({
    method: "delete",
    baseURL: Config.baseUrl,
    url: `/projects/${projectId}/snapshots/${snapshotName}`,
  });
};


export const Projects = {
  get: {
    list: getUserProjects,
    delList: getDeletedList,
    byId: getProjectById,
    snapshot: getProjectSnapshotsById,
    downfile: getProjectFile,
  },
  post: {
    create: postNewProject,
    run: postRunProject,
    upfile: postProjectFile,
    upfilems: postProjectFileMS,
    fork: postFork,
  },
  patch: {
    removeFlg: patchRemove,
    restoreFlg: patchRestore,
    info: patchProjectInfo,
    remove: patchProjectRemove,
    data: patchProjectData,
  },
  delete:{
    snapshot: deleteProjectSnapshotById
  }
};
